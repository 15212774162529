import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Dialog, UploadButton, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { StringUtils } from 'utils'

import { DrawerItem, UpdateDrawerItemApiData } from '../../types'
import { updateDrawerItem } from '../../api'
import { useStyles } from './styles'

const rules = {
  name: [
    {
      validate: (name: string) => !!name,
      message: 'Este campo es requerido.',
    },
  ],
  redirectUrl: [
    {
      validate: (url: string) => StringUtils.isURL(url),
      message: `Debe ser una URL válida.`,
    },
  ],
}

interface Props {
  handleClose: () => void
  onDone: (drawerItem: DrawerItem) => Promise<void>
  drawerItem: DrawerItem
}

const UpdateDrawerItemDialog: React.FC<Props> = ({ handleClose, onDone, drawerItem }) => {
  const classes = useStyles()

  const [name, setName] = useState(drawerItem.name)
  const [redirectUrl, setRedirectUrl] = useState(drawerItem.redirectUrl ?? '')
  const [logo, setLogo] = useState<File | null>(null)
  const [logoPreview, setLogoPreview] = useState(drawerItem.logoUrl ?? '')

  const [updateDrawerItemApi, isLoading] = useApiCall<UpdateDrawerItemApiData, void>(
    updateDrawerItem,
  )

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setName(e.target.value)
  }

  const handleRedirectUrlChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setRedirectUrl(e.target.value)
  }

  const handleChangeLogo = (file: File) => {
    setLogo(file)
    setLogoPreview(URL.createObjectURL(file))
  }

  const fields = { name, redirectUrl, logo }
  const { errors, hasErrors, isAnyFieldEmpty } = useCheckFormErrors(fields, rules)

  const onUpdateDrawerItem = async () => {
    const newItem = { name, redirectUrl, logo: logo ?? undefined }
    try {
      await updateDrawerItemApi({
        id: drawerItem.id,
        drawerItem: newItem,
      })
      onDone({ ...drawerItem, ...newItem })
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo actualizar link. Intente de nuevo.')
    }
  }

  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  return (
    <Dialog
      title="Editar link"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Guardar"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onUpdateDrawerItem}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Nombre"
            value={name}
            fullWidth
            onChange={handleNameChange}
            error={errors.name.hasError}
            helperText={errors.name.message}
            disabled={isLoading}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ style: { textOverflow: 'ellipsis' } }}
            label="URL de redirección"
            fullWidth
            value={redirectUrl}
            onChange={handleRedirectUrlChange}
            error={errors.redirectUrl.hasError}
            helperText={errors.redirectUrl.message}
            disabled={isLoading}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <UploadButton
            id="DI-logo-upload-button"
            accept="image/*"
            label="Cambiar logo"
            onChange={handleChangeLogo}
          />
          <img src={logoPreview} alt="" className={classes.imagePreview} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateDrawerItemDialog }
