import React, { useState, useEffect } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import RedeemIcon from '@material-ui/icons/Redeem'
import CakeIcon from '@material-ui/icons/Cake';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import AddIcon from '@material-ui/icons/Add';

import { ERRORS, PRIZE } from 'consts'
import { useApi, useApiCall, useCheckFormErrors } from 'hooks'

import { getPrizes, updatePrize } from './api'
import { GetPrizesResponse, Prize, UpdatePrizeResponse, UpdatePrizeData } from './types'
import { useStyles } from './styles'
import { Button } from '@material-ui/core'
import { snackbar } from 'components'
// import { CreatePrizeDialog, DeletePrizeDialog, UpdatePrizeDialog } from './components'
// import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const getPrizesResponseGetter = (responseData: GetPrizesResponse) => responseData?.prizes ?? []

const Prizes: React.FC = () => {
  const [{ data: prizes, isLoading }, , setPrizes] = useApi<GetPrizesResponse, Prize[]>(
    getPrizes,
    getPrizesResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const classes = useStyles();

  const setRewardType = (prize: Prize) => {
    if (prize.level)
      return (<><Tooltip title="Premio por Nivel"><FormatListNumberedIcon color="primary" /></Tooltip> Nivel {prize.level}</>)
    else if (prize.welcomePrize)
      return (<><Tooltip title="Premio de bienvenida"><RedeemIcon color="primary" /></Tooltip> Reward de bienvenida</>)
    else if (prize.birthdayPrize)
      return (<><Tooltip title="Premio de cumpleaños"><CakeIcon color="primary" /></Tooltip> Reward de cumpleaños</>)
  }

  const [updatePrizeApi, isLoadingUpdatePrize] = useApiCall<UpdatePrizeData, UpdatePrizeResponse>(updatePrize)
  const [inputValue, setInputValue] = useState('')
  const [currentPrize, setCurrentPrize] = useState<Prize>()
  const [inputField, setInputField] = useState('')

  useEffect(() => {
    if (!currentPrize) return
    const timer = setTimeout(() => {
      onUpdatePrize(currentPrize, inputField, inputValue)
    }, 500)

    return () => clearTimeout(timer)
  }, [inputValue, currentPrize, inputField])

  const onUpdatePrize = async (prize: Prize, field: string, value?: any | File) => {
    let payload: any = {
      title: prize.title || '',
      shortTitle: prize.shortTitle || '',
      level: prize.level,
      rewardType: PRIZE.REWARD_TYPE.AUTOMATIC,
      welcomePrize: prize.welcomePrize,
      birthdayPrize: prize.birthdayPrize || undefined,
      active: prize.active,
      additionalText: prize.additionalText || '',
    }

    if (field == 'active') payload.active = !payload.active
    if (field === 'title') payload.title = value
    if (field === 'shortTitle') payload.shortTitle = value
    if (field === 'icon') payload.icon = value
    if (field === 'additionalText') payload.additionalText = value

    try {
      const { prize: updatedPrice } = await updatePrizeApi({ id: prize.id, payload })
      handleCreatePrizeDone(updatedPrice)
      snackbar.show(`Premio ${(updatedPrice.shortTitle || updatedPrice.title)} modificado`)
    } catch (err) {
      snackbar.show('No se pudo editar reward. Intente de nuevo.')
    }
    setInputValue('')
  }

  const handleCreatePrizeDone = (updatedPrice: Prize) => {
    setPrizes(
      prizes.map(prize => {
        if (updatedPrice.welcomePrize && prize.welcomePrize) {
          const { welcomePrize, ...prizeWithOutWelcomePrize } = prize
          return prizeWithOutWelcomePrize
        }
        if (updatedPrice.id == prize.id) {
          return updatedPrice
        }
        return prize
      })
    )
  }

  const handleIconChange = (event: any, prize: Prize) => {
    if (!event.target.files?.length) return
    const file = event.target.files[0]
    onUpdatePrize(prize, 'icon', file)
  }

  const handleChangeTitle = async (event: any, prize: Prize) => {
    const { value } = event.currentTarget;
    setCurrentPrize(prize)
    setInputField('title')
    setInputValue(value)
  }

  const handleChangeShortTitle = async (event: any, prize: Prize) => {
    const { value } = event.currentTarget;
    if (!value)
    {
      snackbar.show('Este campo no puede estar vacio.')
      return
    }
    if (value.length>22)
    {
      snackbar.show('El máximo de carácteres permitidos es 22.')
      return
    }
    setCurrentPrize(prize)
    setInputField('shortTitle')
    setInputValue(value)
  }

  const handleChangeAdditionalText = async (event: any, prize: Prize) => {
    const { value } = event.currentTarget;
    if (!value)
    {
      snackbar.show('Este campo no puede estar vacio.')
      return
    }
    setCurrentPrize(prize)
    setInputField('additionalText')
    setInputValue(value)
  }

  const handleChangeActive = async (event: any, prize: Prize) => {
    await onUpdatePrize(prize, 'active')
    window.location.reload();
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={4} item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.title}>REWARDS AUTOMATIZADAS</Grid>
            {prizes.map((prize, index) => (
              <Grid container item xs={12} spacing={4} key={`key_${prize.id}`}>
                <Grid container item xs={12} spacing={0} className={classes.rewardType}>
                  <Grid item xs={1} className={classes.checkbox}>
                    <Checkbox
                      id={`active_${prize.id}`}
                      checked={prize.active}
                      onChange={event => handleChangeActive(event, prize)}
                      inputProps={{ 'aria-label': 'Checkbox A' }}
                    />
                  </Grid>
                  <Grid item xs={10}>{setRewardType(prize)}</Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={7}>
                    <Grid item xs={12}>
                      <Input id={`title_${prize.id}`} defaultValue={prize.title} inputProps={{ 'aria-label': 'description' }} placeholder="NOMBRE REWARD" className={classes.input} onChange={event => handleChangeTitle(event, prize)} />
                    </Grid>
                    <Grid item xs={12}>
                      <Input id={`shortTitle_${prize.id}`} defaultValue={prize.shortTitle} inputProps={{ 'aria-label': 'description' }} placeholder="ACLARACIÓN" className={classes.input} onChange={event => handleChangeShortTitle(event, prize)} />
                    </Grid>
                    <Grid item xs={12}>
                      <Input id={`additionalText_${prize.id}`} defaultValue={prize.additionalText} inputProps={{ 'aria-label': 'description' }} placeholder="TEXTO ADICIONAL" className={classes.input} onChange={event => handleChangeAdditionalText(event, prize)} />
                    </Grid>
                  </Grid>
                  <Grid item xs={4} container alignItems="center" className={classes.iconSection}>
                    <div className={classes.iconUploadContainer}>
                      <label htmlFor={`icon_${prize.id}`}>
                        <input
                          style={{ display: 'none' }}
                          id={`icon_${prize.id}`}
                          accept="image/*"
                          type="file"
                          onChange={event => handleIconChange(event, prize)}
                        />
                        <Button variant="contained" color="primary" component="span" startIcon={<AddIcon />}>Icono</Button>
                      </label>
                      <div className={classes.iconUploadPreviewContainer}>
                        <img src={prize.iconUrl} alt="" className={classes.iconPreview} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export { Prizes }
