import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { InputLabel, Select, MenuItem, Input, Chip, FormControl } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'

import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

import { Dialog, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, STRINGS } from 'consts'
import { Moment } from 'moment'

import { createNotification } from '../api'
import { CreateNotificationPayload, CreateNotificationResponse, Notification } from '../types'

import { GetTotalUsersWithAllTagsPayload, GetTotalUsersWithAllTagsResponse } from '../../Tags/types'

import { MultiSelect } from "react-multi-select-component";
import { Tag } from 'features/Main/Tags/types'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
}

interface CreateNotificationsDialogProps {
  handleClose: () => void
  onDone: (newNotification: Notification) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: Tag[], allTagsAreRequired?: boolean) => Promise<number>
}

const CreateNotificationDialog: React.FC<CreateNotificationsDialogProps> = ({ handleClose, onDone, tags, calcUsersByTags }) => {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [scheduled, setScheduled] = useState<Moment | null>(null)
  const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [usersByTags, setUsersByTags] = useState(0)

  const data = { title, body, scheduled }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);
  

  const [allTagsAreRequired, setAllTagsAreRequired] = useState(false)

  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[]);
  };

  const [createNotificationApi, isLoading] = useApiCall<CreateNotificationPayload, CreateNotificationResponse>(
    createNotification,
  )

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))    
  }, [tagsSelected])


  const handleSubmit = async () => {
    if (!scheduled) return
    const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
    const data = {
      title,
      body,
      scheduled: scheduled.toISOString(),
      tagIds: tagsSelectedId,
      allTagsAreRequired
    }
    try {
      const { notification: newNotification } = await createNotificationApi(data)
      onDone(newNotification)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const handleDateChange = (newDate: Moment | null) => {
    setScheduled(newDate)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)    
    setUsersByTags(total)
  }

  return (
    <Dialog
      title="Nueva notificación"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Crear notificación"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mensaje"
            required
            onChange={e => setBody(e.target.value)}
            value={body}
            multiline
            error={errors.body.hasError}
            helperText={errors.body.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            value={scheduled}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_TIME_FORMAT}
            initialFocusedDate={new Date()}
            disabled={isLoading}
            style={{ width: '100%' }}
            label="Programado"
            required
          />
        </Grid>
        <Grid item xs={11}>
          <InputLabel>Tags</InputLabel>
          <MultiSelect
            options={tagsForSelect}
            value={tagsSelected}
            onChange={handleSetTags}
            labelledBy={"Select"}
            hasSelectAll={false}
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handleAllTagsAreRequiredChange}
              checked={allTagsAreRequired}
            />}
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={11}>
          <InputLabel>Esta acción impactará a {usersByTags} usuarios</InputLabel>
        </Grid>
      </Grid>
    </Dialog >
  )
}

const names = [
  'TAG-1',
  'TAG-2',
  'TAG-3',
  'TAG-4',
  'TAG-5'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    fullWidth: {
      width: '100%',
    },
  }),
);

export { CreateNotificationDialog }
